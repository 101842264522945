@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    height: 100;
    font-family: 'Poppins', sans-serif;
    background-color: #111526;
    color: #FFF;
}

img {
    width: 100%;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

.navbar-brand img {
    width: 80px;
    height: auto;
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
    .new-log-reg-forms ul .new-log-reg-child-1 {
        width: 50% !important;
    }

    .new-log-reg-forms ul .new-log-reg-child-2 {
        width: 50% !important;
    }

    .new-log-reg-forms .new-log-reg-btn-1 {
        width: 100% !important;
        border: none !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 0 !important;
    }

    .new-log-reg-forms .new-log-reg-btn-2 {
        width: 100% !important;
        border: none !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
    }

    .new-login-btn-01 {
        border-radius: 4px;
    }

    .new-full-login-box {
        z-index: 999;
        border-radius: 10px;
        background-color: #1bebe436;
        background: rgb(0 81 255 / 34%);
        backdrop-filter: blur(30px);
    }

    .new-bg-int-box {
        /* border: 1px solid #1bebe436; */
        outline: none;
        background: none !important;
        border: 1px solid rgb(0 81 255 / 67%);
    }
    .new-bg-int-box:hover {
        border: 1px solid rgb(0 81 255 / 67%);
        outline: none;
        background: none !important;
    }
    .new-bg-int-box::placeholder {
        color: white;
    }

    .new-eagle-img-01 {
        width: 100px;
        height: 100px;
    }

    .new-eagle-log-ctn {
        font-size: 25px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .new-lg-sq-box-01::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        right: -21px;
        top: -30px;
        background: linear-gradient(151deg, #1bebe436 0%, #1bebe436 100%);
        background: rgb(0 81 255 / 79%);
        z-index: -999 !important;
        border-top-right-radius: 15px;
        opacity: 0.6;
    }

    .new-lg-sq-box-01::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        /* background-color: #663dff;
        background: linear-gradient(151deg, #1bebe436 0%, #1bebe436 100%); */
        background: rgb(0 81 255 / 79%);
        left: -28px;
        bottom: -22px;
        border-bottom-left-radius: 15px;
        opacity: 0.6;
    }

    .new-full-login-box .nav-tabs .nav-item.show .nav-link,
    .new-full-login-box  .nav-tabs .nav-link.active {
        background-color: #1bebe436;
        background-color: rgb(0 81 255 / 34%);
    }

    .new-bleeding-icon {
        position: absolute;
        top: 10px;
        right: 9px;
        font-size: 22px;
        color: grey;
    }

    .new-mail-lock-icon {
        position: absolute;
        top: 33px;
        right: 9px;
        font-size: 22px;
        color: grey;
    }

    .new-ul-btm-01 {
        border: none !important;
    }

    .new-full-login-box .otp-input-container {
        display: flex;
        justify-content: space-between;
    }

    .new-full-login-box .otp-input {
        width: 40px !important;
        height: 40px !important;
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
        border: 1px solid rgb(0 81 255 / 67%) !important;
        outline: none !important;
        background: none;
        border-radius: 5px;
        color: #FFF;
    }

    .new-reg-map-img {
        width: 20px;
        height: 20px;
        rotate: 180deg;
    }

    .new-get-otp-btn-10 {
        background-color: #1bebe436;
        /* background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%); */
        background: rgb(0 81 255 / 79%);
        color: white;
        border: none !important;
    }
    .new-get-otp-btn-10:hover {
        background-color: #1bebe436;
    }
    .new-get-otp-cls-btn {
        padding: 6px 24px;
        border: 0;
        border-radius: 10px;
        background-color: #1bebe436;
        /* background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%); */
        background: rgb(0 81 255 / 79%);
        color: white;
    }

    .new-child-auth-box {
        /* background-color: #00563B; */
        /* background-color: #001000; */
        /* background-color: #2F539B; */
        /* background-color: #2B547E; */
        /* background-color: #151B54; */
        /* background-color: #368BC1; */
        /* background-color: #007C80; */
        /* background-color: #307d7e33; */
        background: rgb(0 81 255 / 25%);
    }
    .new-full-login-box .form-control:focus{
        box-shadow: none!important;
        /* border-color: #1bebe485; */
        border-color: rgb(0 81 255 / 67%);
    }
    .new-full-login-box .otp-input:focus{
        /* border-color: #1bebe485 !important;  */
        border-color: rgb(0 81 255 / 67%);
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none!important;
      /* margin: 0!important; */
    }
    /* .hhhhhh{
        width:50px;
        height:50px
    } */
}

/* #171B2E */

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
    .new-log-reg-forms ul .new-log-reg-child-1 {
        width: 50% !important;
    }

    .new-log-reg-forms ul .new-log-reg-child-2 {
        width: 50% !important;
    }

    .new-log-reg-forms .new-log-reg-btn-1 {
        width: 100% !important;
        border: none !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 0 !important;
    }

    .new-log-reg-forms .new-log-reg-btn-2 {
        width: 100% !important;
        border: none !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
    }

    .new-login-btn-01 {
        border-radius: 4px;
    }

    .new-full-login-box {
        z-index: 999;
        border-radius: 10px;
        background-color: #1bebe436;
        background: rgb(0 81 255 / 34%);
        backdrop-filter: blur(30px);
    }

    .new-bg-int-box {
        /* border: 1px solid #1bebe436; */
        outline: none;
        background: none !important;
        border: 1px solid rgb(0 81 255 / 67%);
    }
    .new-bg-int-box:hover {
        border: 1px solid rgb(0 81 255 / 67%);
        outline: none;
        background: none !important;
    }

    .new-bg-int-box::placeholder {
        color: white;
    }

    .new-eagle-img-01 {
        width: 100px;
        height: 100px;
    }

    .new-eagle-log-ctn {
        font-size: 25px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .new-lg-sq-box-01::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        right: -21px;
        top: -30px;
        background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%);
        background: rgb(0 81 255 / 79%);
        z-index: -999 !important;
        border-top-right-radius: 15px;
    }

    .new-lg-sq-box-01::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        /* background-color: #663dff;
        background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%); */
        background: rgb(0 81 255 / 79%);
        left: -28px;
        bottom: -22px;
        border-bottom-left-radius: 15px;
    }

    .new-full-login-box .nav-tabs .nav-item.show .nav-link,
    .new-full-login-box .nav-tabs .nav-link.active {
        background-color: #0d0a0b;
        /* background-image: linear-gradient(315deg, #0d0a0b 0%, #009fc2 74%); */
        background-color: rgb(0 81 255 / 34%);
    }

    .new-bleeding-icon {
        position: absolute;
        top: 10px;
        right: 9px;
        font-size: 22px;
        color: grey;
    }

    .new-mail-lock-icon {
        position: absolute;
        top: 33px;
        right: 9px;
        font-size: 22px;
        color: grey;
    }

    .new-ul-btm-01 {
        border: none !important;
    }

    .new-full-login-box .otp-input-container {
        display: flex;
        justify-content: space-between;
    }

    .new-full-login-box .otp-input {
        width: 40px !important;
        height: 40px !important;
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
        border: 1px solid #1bebe436 !important;
        outline: none !important;
        background: none;
        border-radius: 5px;
        color: #FFF;
    }

    .new-reg-map-img {
        width: 20px;
        height: 20px;
        rotate: 180deg;
    }

    .new-get-otp-btn-10 {
        background-color: #1bebe436;
        /* background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%); */
        background: rgb(0 81 255 / 79%);
        color: white;
        border: none !important;
    }
    .new-get-otp-btn-10:hover {
        background-color: #1bebe436;
    }
    .new-get-otp-cls-btn {
        padding: 6px 24px;
        border: 0;
        border-radius: 10px;
        background-color: #1bebe436;
        /* background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%); */
        background: rgb(0 81 255 / 79%);
        color: white;
    }

    .new-child-auth-box {
        background-color: #00563B;
    }
    .new-full-login-box .form-control input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .new-child-auth-box {
        /* background-color: #00563B; */
        /* background-color: #001000; */
        /* background-color: #2F539B; */
        /* background-color: #2B547E; */
        /* background-color: #151B54; */
        /* background-color: #368BC1; */
        /* background-color: #007C80; */
        /* background-color: #307D7E; */
        background: rgb(0 81 255 / 25%);
    }
    .new-full-login-box .form-control:focus{
        box-shadow: none!important;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none!important;
      /* margin: 0!important; */
    }
}

/* ============ COMMON-CSS ============  */
/* background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%); */
/* background-color: #0cbaba;
background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%); */
/* background-color: #7a7adb;
background-image: linear-gradient(315deg, #7a7adb 0%, #170e13 74%); */
/* background-color: #041E42!important; */
/* border-bottom:2px solid #171B2E !important; */
/* background-color: #182b3a;
background-image: linear-gradient(315deg, #182b3a 0%, #20a4f3 74%); */
/* background-color: #0d0a0b;
background-image: linear-gradient(315deg, #0d0a0b 0%, #009fc2 74%); */
/* background-color: #182b3a;
background-image: linear-gradient(315deg, #182b3a 0%, #20a4f3 74%); */
/* background-color: #4062bb;
background-image: linear-gradient(316deg, #4062bb 0%, #5200ae 74%); */
/* background-color: #310e68;
background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%); */
/* background-color: #166d3b;
background-image: linear-gradient(147deg, #166d3b 0%, #000000 74%); */
/* background-color: #1bebe436; */
/* background: linear-gradient(90deg, #5F0F40, #310E68);  */
/* background: linear-gradient(90deg, #191714, #2234AE);  */
/* background-color: rgb(34, 34, 34); */
/* color:#002D62; */
/* background:#171B2E ; */
/* background: linear-gradient(to right, #5200AE, #4062BB); */
/* background: linear-gradient(to right, #5F0F40, #310E68); */
/* #00087A */


/* background-color: #0d0a0b;
background-image: linear-gradient(315deg, #0d0a0b 0%, #009fc2 74%); */
/* background-color: #182b3a;
background-image: linear-gradient(315deg, #182b3a 0%, #20a4f3 74%); */
/* background-color: #0d0a0b;
background-image: linear-gradient(315deg, #0d0a0b 0%, #009fc2 74%); */