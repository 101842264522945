@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100;
  font-family: 'Poppins', sans-serif;
  background-color: #111526;
  color: #FFF;
}

img {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.navbar-brand img {
  width: 80px;
  height: auto;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}


.dash-profile-section-1 {
  background-color: #171C31;
  padding: 1.8vw;
  margin-top: 20px;
  border-radius: 16px;
}

.dash-profile-active-bot-1 {
  background-color: #171C31;
  padding: 1vw;
  margin-top: 20px;
  border-radius: 16px;
}

.react-switch {
  vertical-align: middle;
  scale: 0.8;
}

.dash-profile-active-bot-2 {
  background-color: #171C31;
  padding: 1vw;
  margin-top: 20px;
  border-radius: 16px;
}

.dash-profile-create-bot-1 {
  background-color: #171C31;
  padding: 2vw;
  margin-top: 20px;
  border-radius: 16px;
}

::placeholder {
  color: #FFF !important;
  opacity: 0.6 !important;
}

@keyframes createnew {
  0% {
    box-shadow: 0 0 0 0 #ffffff49;
    -webkit-transform: scale(.95);
    transform: scale(.95);
  }

  70% {
    box-shadow: 0 0 0 10px transparent;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transform: scale(.95);
    transform: scale(.95);
  }
}

.dash-profile-create-bot-1-1 .form-control {
  background-color: transparent;
  color: #FFF;
  border-radius: 0px;
  border-bottom: 1px solid #ffffff54 !important;
  border: none;
  box-shadow: none !important;
}

.dash-profile-create-bot-4-1 .form-control {
  background-color: transparent;
  color: #FFF;
  border-radius: 0px;
  border-bottom: 0px solid #ffffff54 !important;
  border: none;
  box-shadow: none !important;
  height: 46px;
  text-align: end;
  padding-right: 0px;
}

.dash-profile-create-bot-4-1 {
  border-bottom: 1px solid #ffffff73 !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
}

.dash-profile-create-bot-4-1 .form-select {
  background-color: transparent;
  border: 1px solid #ffffff83 !important;
  color: #FFF;
  box-shadow: none;
  filter: brightness(0) invert(1);
  font-size: 14px;
}

.dash-profile-create-bot-4-1 .form-select option {
  color: #000;
}


/* ============ DESKTOP-VIEW ============  */

/* @media all and (min-width: 992px) { */
.create-bot-text-2 {
  font-size: 16px;
  letter-spacing: 0.5px;
}

/* .select-exchange-new-css div div div{
    color: #FFF;
  } */
.select-exc-image-1 {
  width: 20px;
  height: 20px;
}

.create-bot-text-1 {
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 800;
}

.create-bot-save-2 {
  background: transparent;
  color: #FFF;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 6px;
  border: none;
  width: fit-content;
  font-weight: 800;
  letter-spacing: 1px;
  border: 1px solid #ffffff73;
}

.create-bot-save-2:hover {
  background: #ffffff;
  color: #000;
  border: 1px solid #ffffff73;
}

.create-bot-btn-1 {
  width: 220px;
  height: 60px;
  background-color: #FFF;
  border: none;
  color: #000;
  border-radius: 50px;
  animation: createnew 2s infinite;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  top: -15px;
}

.create-bot-save-1 {
  background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%);
  color: #FFF;
  font-size: 16px;
  padding: 14px 30px;
  border-radius: 6px;
  border: none;
  width: fit-content;
  font-weight: 800;
  letter-spacing: 1px;
}

.bot-name-input-section {
  border: 1px solid #ffffff54;
  width: fit-content;
  border-radius: 6px;
}

.bot-name-input-section .input-group-text {
  background-color: transparent;
  color: #FFF;
  border: none;
  padding: 20px 10px 20px 20px;
  font-size: 20px;
}

.bot-name-input-section .form-control {
  background-color: transparent;
  color: #FFF;
  border: none;
  padding: 20px 20px 20px 10px;
  box-shadow: none;
  width: 300px;
}

.dash-float-image-1 {
  position: absolute;
  top: -30px;
  right: -20px;
  width: 90px;
  height: auto;
}

.active-bot-2-table-td {
  padding: 1% 2%;
  font-size: 14px;
  border-bottom: 1px solid #ffffff00;
}

.active-bot-2-table-td:hover {
  /* transform: scale(1.02); */
  border-bottom: 1px solid #ffffff17;
}

.active-bot-2-table-th {
  background-color: #ffffff0e;
  padding: 1% 2%;
  border-bottom: 1px solid #ffffff4d;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 12px;
  opacity: 0.7;
}

.active-bot-2-td-1 {
  width: 10%;
}

.active-bot-2-td-2 {
  width: 25%;
}

.active-bot-2-td-3 {
  width: 20%;
}

.active-bot-2-td-4 {
  width: 25%;
}

.active-bot-2-td-5 {
  width: 20%;
}

.view-active-bot-btn-1 {
  background-color: transparent;
  width: 180px;
  color: #FFF;
  font-size: 13px;
  border: 0px;
  padding-bottom: 10px;
}

.view-active-bot-btn-1 {
  position: relative;
  display: inline-block;
}

.view-active-bot-btn-1:before {
  content: '';
  width: 0;
  height: 1px;
  background: #1bebe4;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: -99;
}

.dash-profile-active-bot-1 .table tr:hover .view-active-bot-btn-1:before {
  width: 100%;
}

.active-bot-table-text-1 {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50px;
  display: flex;
  /* background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%); */
  align-items: center;
  justify-content: center;
  /* color: #000; */
  background: rgb(0 81 255 / 79%);
}

.dash-profile-active-bot-1 .table tr {
  transition: 0.2s;
  border-radius: 50px;
}


.dash-profile-active-bot-1 .table tr:hover {
  background-color: #171C31;
  transform: scale(1.02);
  box-shadow: #ffffff0e 0px 7px 29px 0px;
}

.dash-profile-active-bot-1 .table tr td:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 80px;
}

.dash-profile-active-bot-1 .table tr td:nth-child(2) {
  width: 180px;
  font-size: 18px;
  font-weight: 800;
}

.dash-profile-active-bot-1 .table tr td:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 200px;
}

.dash-profile-active-bot-1 .table>:not(caption)>*>* {
  background-color: transparent;
  color: #FFF;
  border: 0px;
  padding: 10px 14px;
  box-shadow: none;
  vertical-align: middle;
  font-size: 16px;
}

.dash-profile-text-1 {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.profile-btn-2:hover {
  scale: 1.4;
}

.profile-btn-2 {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #FFF;
  font-size: 22px;
  transition: 0.5s;
}

.profile-text-1 {
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: 800;
  letter-spacing: 1px;
}

.profile-btn-1 {
  /* background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%); */
  color: #FFF;
  font-size: 12px;
  padding: 5px 14px;
  border-radius: 6px;
  border: none;
  width: fit-content;
  margin: auto;
  background: rgb(0 81 255 / 79%);
}

.profile-image-1 {
  width: 30%;
  height: auto;
  border-radius: 50%;
}

.dashboard-left-icon-1 {
  margin-right: 6px;
  font-size: 22px;
}

.dashboard-left-section .nav-link {
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 50px;
  text-transform: uppercase;
  border-radius: 0px;
  color: #FFF;
  height: 50px;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.dashboard-left-section .nav-link::after:hover {
  background-color: #0d586433;
}

.dashboard-left-section .nav-pills .nav-link.active,
.dashboard-left-section .nav-pills .show>.nav-link {
  /* background: linear-gradient(151deg, #1bebe436 0%, #1bebe436 100%); */
  color: #FFF;
  background: rgb(0 81 255 / 79%);
}

.dashboard-left-section .nav-link.active::after {
  content: "";
  background-color: white;
  position: absolute;
  left: 0px;
  width: 30px;
  height: 50px;
  clip-path: polygon(0 0, 12% 0, 60% 50%, 12% 100%, 0 100%, 0% 50%);

}

.dashboard-left-section .dashboard-left-navbar-logo {
  width: 80px;
  height: auto;
}

/* } */

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .dashboard-left-section {
    position: -webkit-sticky;
    position: fixed;
    top: 0;
    width: 20%;
    background-color: #171B2E;
    height: 100vh;
  }

  .dashboard-right-section {
    margin-left: 20%;
    width: 80%;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {}

/* ============ COMMON-CSS ============  */

.css-1dimb5e-singleValue {
  color: #FFF !important;
}

.css-1p3m7a8-multiValue {
  background-color: #FFF !important;
}

.change-password-dash-modal-section .modal-content {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  /* background: rgba(27, 159, 254, .1); */
  border: 1px solid #00e5ff21;
  color: #FFF;
}

.change-password-dash-modal-section .btn-close {
  filter: blur(0) invert(1);
  box-shadow: none;
}

.change-password-dash-modal-section .modal-header {
  border-bottom: 0px solid #ffffff1f;
}

.change-password-dash-modal-section .form-label {
  font-size: 14px;
}

.change-password-dash-modal-section .form-control {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(27, 159, 254, .1);
  border: 1px solid #00e5ff21;
  color: #FFF;
  box-shadow: none;
  padding: 10px;
  font-size: 14px;
}

.change-pass-btn {
  background: #00e5ff41;
  border: 1px solid #00e5ff21;
  color: #FFF;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
}

.mm-authenticator-page .mm-new-int-child-box {
  padding: 10px;
  border: 1px solid white;
  background-color: transparent;
  margin-top: 10px;
}

.mm-authenticator-page .mm-new-enable-btn {
  background-color: red;
  border: 0;
  color: white;
  border-radius: 6px;

}

.green {
  background-color: #1bebe436;
}

.mm-authenticator-page .mm-new-modal-ctn {
  /* background: #002147 !important; */
  background: rgb(0 27 84) !important;
}

.mm-authenticator-page .mm-qr-new-scanning {
  width: 200px;
  height: 200px;
}

.mm-authenticator-page .mm-new-copy-scan {
  padding: 10px;
  width: 200px;
  margin: 0px auto;
  background: #3535b3;
  border-radius: 5px;
}

.mm-authenticator-page .mm-auth-input-cd {
  padding: 10px;
  border: 1px solid white;
  color: #FFF;
  border-radius: 4px;
  outline: none !important
}

.btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
}

.mm-authenticator-page .mm-auth-enable-btn {
  background: transparent;
  border-radius: 5px;
  padding: 6px 10px;
  border: 0;
  outline: none;
  background: #3535b3;
  color: white;
}

.mm-authenticator-page .modal-header {
  border-bottom: none !important;
}

.qr-bg {
  border: 5px solid white;
}

.select-exchange-new-css-001 {
  border-radius: 4px;
  border: 1px solid #ffffff54;
  width: 100%;
  padding: 10px;
  background: transparent;
  color: white;
  outline: none !important;
  display: flex !important;
  justify-content: space-around !important;
}

.auth-switch {
  background: transparent;
  outline: none;
  border: none;
}

.custom-model-1 {
  background-color: #171C31;
}

.upload {
  padding: 8px 10px;
  border-radius: 6px;
  outline: none;
  border: 0;
  margin: 0px auto;
  width: 100%;
  background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%);
  color: white;
}

.cursor {
  cursor: pointer;
}
.cursor-01{
  /* background: linear-gradient(151deg, #1bebe436 0%, #6589dd44 100%); */
  border-radius:5px;
  background: rgb(0 81 255 / 79%);
}
.new-alt-table-01{
  width:100%;
  background-color: #111526;
  border-radius:10px;
}
.new-alt-table-01 td,th{
  padding:10px 5px;
  
}